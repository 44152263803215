import { TeamPlayerMap } from '../types/common';
import { Game } from '../types/game';
import { GamePreview } from '../types/scoreboard';
import { Information } from '../types/player';
import { teamIds } from './teamsData';

export const generateTeamPlayerMap = (): TeamPlayerMap => {
  const map = Object.keys(teamIds).reduce((acc, team) => {
    acc[team] = [];
    return acc;
  }, {} as TeamPlayerMap);

  // Ensure all team IDs have an array, even if not in teamIds
  // This prevents errors when a player has a team ID not in our list
  for (let i = 1600000000; i < 1600000100; i++) {
    const teamId = i.toString();
    if (!map[teamId]) {
      map[teamId] = [];
    }
  }

  return map;
};

export const parseTimeStamp = (minutes: string): string => {
  const minArray = minutes.split(/[TM.]+/);
  return `${Number(minArray[1])}:${minArray[2]}`;
};

export const parseMinutes = (minutes: string): string => {
  const minArray = minutes.split(/[TM.]+/);
  return `${Number(minArray[1])}`;
};

export const parsePlusMinus = (plusMinus: string): string => {
  return plusMinus[0] === '-' ? plusMinus : `+${plusMinus}`;
};

export const parseQuarter = (quarter: number): string => {
  if (quarter < 5) {
    return `${quarter}Q`;
  }
  return `${quarter === 5 ? '' : quarter - 4}OT`;
};

export const parseQuarterFull = (quarter: number): string => {
  switch (quarter) {
    case 1:
      return '1st Quarter';
    case 2:
      return '2nd Quarter';
    case 3:
      return '3rd Quarter';
    case 4:
      return '4th Quarter';
    default:
      return `Overtime ${quarter === 5 ? '' : quarter - 4}`;
  }
};

export const hasGameEnded = (gameData: Game | GamePreview): boolean => {
  return Boolean(gameData && gameData.gameStatus === 3);
};

export const hasGameStarted = (gameData: Game | GamePreview): boolean => {
  return (
    new Date() > new Date(gameData.gameEt) &&
    (gameData.period > 0 || hasGameEnded(gameData))
  );
};

export const parseGamePreviewStatus = (
  gameData: Game | GamePreview
): string => {
  const { gameClock, gameStatusText, gameTimeUTC } = gameData;

  // If game is at halftime, just return "Halftime"
  if (gameStatusText.includes('Halftime')) {
    return gameStatusText;
  }

  // Parse game clock format like "PT08M47.00S" to "8:47"
  // If game hasn't started yet, show the scheduled time
  if (!hasGameStarted(gameData)) {
    const gameTime = new Date(gameTimeUTC);
    return gameTime.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      timeZone: 'America/New_York',
    });
  }

  // If game has ended, show "Final"
  if (hasGameEnded(gameData)) {
    return 'Final';
  }

  // If we have a game clock, parse it
  if (gameClock) {
    // Extract minutes and seconds from format like "PT08M47.00S"
    const minutesMatch = gameClock.match(/PT(\d+)M/);
    const secondsMatch = gameClock.match(/M([\d.]+)S/);

    const minutes = minutesMatch ? parseInt(minutesMatch[1]) : 0;
    const seconds = secondsMatch ? Math.floor(parseFloat(secondsMatch[1])) : 0;

    // If time is 0:00, show "End of XQ"
    if (minutes === 0 && seconds === 0) {
      const periodText = parseQuarter(gameData.period);
      return `End of ${periodText}`;
    }

    // Format the time as "M:SS"
    const formattedTime = `${minutes}:${
      seconds < 10 ? '0' + seconds : seconds
    }`;

    // Add the quarter/period information
    const periodText = parseQuarter(gameData.period);

    return `${formattedTime} ${periodText}`;
  }

  // Fallback to the game status text if we can't parse the clock
  return gameStatusText;
};

const reverseQuarter = (quarter: string): string => {
  return quarter[1] + quarter[0];
};

export const parseGameStatus = (gameData: Game | GamePreview): string => {
  const { gameStatusText } = gameData;
  const gameStatusArray = gameStatusText.split(' ');
  return gameStatusText.toLowerCase().includes('final')
    ? gameStatusText
    : gameStatusText.toLowerCase().includes('end')
      ? `End of ${reverseQuarter(gameStatusText.trim().slice(-2))}`
      : gameStatusText.toLowerCase().includes('half')
        ? 'Halftime'
        : `${gameStatusArray[1]} ${
            gameStatusText.includes('OT')
              ? gameStatusArray[0]
              : reverseQuarter(gameStatusArray[0])
          }`;
};

export const getTeamRecord = (
  gameData: GamePreview,
  isHome: boolean
): string => {
  return `${isHome ? gameData.homeTeam.wins : gameData.awayTeam.wins}-${
    isHome ? gameData.homeTeam.losses : gameData.awayTeam.losses
  }`;
};

export const getAverageStat = (total: number, games: number): number => {
  return Math.round((total / games) * 10) / 10;
};

export const calculateAge = (birthday: string): number => {
  const ageDifMs = Date.now() - new Date(birthday).getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const formatPlayerInfo = (information: Information): string => {
  return information.pos
    ? `${information.pos.replace('-', '/')} \u00B7 ${
        teamIds[information.teamId][0]
      } \u00B7 #${information.jersey}`
    : '';
};

export const formatStat = (teamStat: string): string => {
  return String(Number(teamStat).toFixed(1));
};
