import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePlayer } from '../utils/fetchers';
import { useLocalStorage } from '../hooks/useLocalStorage';
import {
  calculateAge,
  formatPlayerInfo,
  generateTeamPlayerMap,
} from '../utils/dataParsers';
import Stat from '../components/Stat';
import styled from 'styled-components';
import StatContainer from '../components/StatContainer';
import BackButton from '../components/BackButton';
import { TeamPlayerMap } from '../types/common';
import SeasonTable from '../components/SeasonTable';
import { useWindowSize } from '../hooks/useWindowSize';
import Container from '../components/Container';
import Loader from '../components/Loader';
import CardContainer from '../components/CardContainer';
import Button from '../components/Button';
import { SmallText } from '../components/Typography';
import { theme } from '../utils/theme';

const PlayerContainer = styled.div`
  background: ${(props) => props.theme.colors.gray[0]};
  min-height: 100vh;
`;

const PlayerPhoto = styled.div`
  display: flex;
  width: 112px;
  height: 112px;
  border-radius: 50%;
  justify-content: center;
  overflow: hidden;
  @media (max-width: ${(props) => props.theme.viewports.mobile.max}px) {
    width: 60px;
    height: 60px;
    margin-bottom: 8px;
  }
`;

const PlayerHeader = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.white};
  padding-bottom: 28px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray[2]};
  @media (max-width: ${(props) => props.theme.viewports.mobile.max}px) {
    padding-bottom: 20px;
  }
`;

const HeaderInfo = styled.div`
  display: flex;
  margin-top: 20px;
  @media (max-width: ${(props) => props.theme.viewports.mobile.max}px) {
    flex-direction: column;
  }
`;

const PlayerInfo = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${(props) => props.theme.viewports.mobile.max}px) {
    flex: 1;
  }
`;

const PlayerInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  flex: 1;
  span {
    color: ${(props) => props.theme.colors.gray[5]};
  }
  @media (max-width: ${(props) => props.theme.viewports.mobile.max}px) {
    margin-left: 0;
  }
`;

const PlayerInfoRow = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-left: 24px;
  }
  margin-bottom: 12px;
`;

const StatTableContainer = styled(CardContainer)`
  margin-bottom: 20px;
`;

const AllStatsContainer = styled(Container)`
  padding-bottom: 40px;
`;

const PlayerPage: React.FC = () => {
  const params = useParams();
  const pid = params.pid || '';

  const [playersFollowing, setPlayersFollowing] = useLocalStorage<string[]>(
    'playersFollowing',
    []
  );
  const [teamPlayerMap, setTeamPlayerMap] = useLocalStorage<TeamPlayerMap>(
    'teamPlayerMap',
    generateTeamPlayerMap()
  );

  console.log(teamPlayerMap);

  const [isHover, setIsHover] = useState<boolean>(false);
  const { width } = useWindowSize();

  const { playerProfile, isLoading } = usePlayer(pid);

  const isFollowing = playersFollowing.includes(pid);

  useEffect(() => {
    document.title = playerProfile.information
      ? `${playerProfile.information.firstName} ${playerProfile.information.lastName}`
      : 'Boxscores';
  });

  const updateFollowing = () => {
    const { teamId } = playerProfile.information;

    // Safety check - ensure teamId exists and teamPlayerMap has an entry for it
    if (!teamId) {
      console.error('Player has no team ID');
      return;
    }

    // Initialize the team array if it doesn't exist
    if (!teamPlayerMap[teamId]) {
      teamPlayerMap[teamId] = [];
    }

    if (isFollowing) {
      // Unfollow player
      setPlayersFollowing(
        playersFollowing.filter((player: string) => player !== pid)
      );
      setTeamPlayerMap({
        ...teamPlayerMap,
        [teamId]: teamPlayerMap[teamId].filter(
          (player: string) => player !== pid
        ),
      });
    } else {
      // Follow player
      setPlayersFollowing([...playersFollowing, pid]);
      setTeamPlayerMap({
        ...teamPlayerMap,
        [teamId]: [...teamPlayerMap[teamId], pid],
      });
    }
  };

  if (isLoading)
    return (
      <PlayerContainer>
        <PlayerHeader>
          <Container margin>
            <Loader height={160} instances={1} radius={4} />
          </Container>
        </PlayerHeader>
        <Container>
          <CardContainer>
            <Loader height={28} instances={5} radius={4} />
          </CardContainer>
        </Container>
      </PlayerContainer>
    );

  if (Object.keys(playerProfile).length === 0)
    return (
      <Container margin>
        <BackButton />
        <h2>No player found</h2>
      </Container>
    );

  const { information } = playerProfile;

  return (
    <PlayerContainer>
      <PlayerHeader>
        <Container margin>
          <BackButton />
          <HeaderInfo>
            <PlayerPhoto>
              <img
                src={`https://cdn.nba.com/headshots/nba/latest/1040x760/${pid}.png`}
                width={width && width > theme.viewports.mobile.max ? 153 : 82}
                height={width && width > theme.viewports.mobile.max ? 112 : 60}
                alt="Portrait"
              />
            </PlayerPhoto>
            <PlayerInfoContainer>
              <PlayerInfoRow>
                <PlayerInfo>
                  <h2>
                    {information.firstName} {information.lastName}
                  </h2>
                  <SmallText>{formatPlayerInfo(information)}</SmallText>
                  <SmallText>
                    {information.heightFeet}′{information.heightInches} &middot;{' '}
                    {information.weightPounds} lbs &middot;{' '}
                    {calculateAge(information.dateOfBirthUTC)} years old
                  </SmallText>
                </PlayerInfo>
                <Button
                  onClick={updateFollowing}
                  onMouseEnter={() => setIsHover(true)}
                  onMouseLeave={() => setIsHover(false)}
                  outline={isFollowing}
                >
                  {isFollowing
                    ? isHover
                      ? 'Unfollow'
                      : 'Following'
                    : 'Follow'}
                </Button>
              </PlayerInfoRow>
              <StatContainer noPadding>
                <Stat
                  label="ppg"
                  value={playerProfile.stats[0].rowSet[0][21]}
                />
                <Stat
                  label="apg"
                  value={playerProfile.stats[0].rowSet[0][16]}
                />
                <Stat
                  label="rpg"
                  value={playerProfile.stats[0].rowSet[0][15]}
                />
                <Stat
                  label="spg"
                  value={playerProfile.stats[0].rowSet[0][18]}
                />
                <Stat
                  label="bpg"
                  value={playerProfile.stats[0].rowSet[0][19]}
                />
                <Stat label="fg%" value={playerProfile.stats[0].rowSet[0][6]} />
                <Stat label="3p%" value={playerProfile.stats[0].rowSet[0][9]} />
                <Stat
                  label="ft%"
                  value={playerProfile.stats[0].rowSet[0][12]}
                />
              </StatContainer>
            </PlayerInfoContainer>
          </HeaderInfo>
        </Container>
      </PlayerHeader>
      <AllStatsContainer>
        <StatTableContainer>
          <h3>Regular Season Averages</h3>
          <SeasonTable
            keys={playerProfile.stats[5].headers}
            values={playerProfile.stats[5].rowSet}
            windowWidth={width}
          />
        </StatTableContainer>
        {/* <StatTableContainer>
          <h3>Advanced Stats</h3>
          <SeasonTable
            keys={playerProfile.stats[6].headers}
            values={playerProfile.stats[6].rowSet}
            windowWidth={width}
          />
        </StatTableContainer> */}
        {/* <StatTableContainer>
          <h3>Regular Season Totals</h3>
          <SeasonTable
            seasons={playerStats.regularSeason.season}
            careerSummary={playerStats.careerSummary}
            windowWidth={width}
            totals
          />
        </StatTableContainer> */}
      </AllStatsContainer>
    </PlayerContainer>
  );
};

export default PlayerPage;
